import { authOperations,  authSelectors,  hooks } from 'state';
import { Outlet } from 'react-router-dom';
import { Container, LoadingScreen } from 'components';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { dataTrackingOperations } from 'state/ducks/data-tracking';
import { cookifyString, cookifyObj, getUserFromCookies, getUserCookieParametersConfig, getSubCookieNames } from 'utils';


const StyledPrivateRoute = styled(Container)`
  max-height: 100%;
  .body-container {
    flex: 1;
  }
`

export const PrivateRoute = () => {
  const cookieUserConfig = getUserCookieParametersConfig();
  const cookieNames = getSubCookieNames();
  const [, setCookie] = useCookies(Object.values(cookieNames));
  const userCookie = getUserFromCookies();
  const isCookieValid = Number(userCookie?.cookie_expiration_date) > new Date().getTime();
  
  const { useAppDispatch, useAppSelector } = hooks
  const dispatch = useAppDispatch()
  const { selectAuth } = authSelectors
  const { user, sessionId } = useAppSelector(selectAuth)
  const { getUser, silentRenew } = authOperations
  const { trackingInit, trackEvent } = dataTrackingOperations

  const setUserCookie = async (cookieLabelName: string, cookieBase64StringValue: string) => {
    setCookie(cookieLabelName, cookieBase64StringValue, cookieUserConfig);
  }

  useEffect(() => {
    const storedValue = localStorage.getItem('previousTimestamp') ?? "";

    const previousTimestamp = !isNaN(parseInt(storedValue, 10)) ? parseInt(storedValue, 10) : 0;
    const currentTimestamp = Date.now();

    if (!isCookieValid && userCookie) {
      const originApp = sessionStorage.getItem('originApp')
      const destinationApp = sessionStorage.getItem('destinationApp')
      if (originApp !== 'time-out') {
        window.location.assign(`${process.env.REACT_APP_BASE_URL}/time-out?destinationApp=${originApp}`)
      }
    } else {
      // Only do silent login (which refreshes claims/tokens) once every 12 hours (miliseconds > 12 hours * to seconds * to miliseconds)
      if (userCookie && isCookieValid && (!previousTimestamp || (currentTimestamp - previousTimestamp) >= 12 * 3600 * 1000)) {
        localStorage.setItem('previousTimestamp', currentTimestamp.toString());
        dispatch(silentRenew(userCookie))
      } else {
        dispatch(getUser(userCookie))
      }
    }
  }, []);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (user) {
      if(!userCookie) {
        const newUser = {
          ...user,
          session_id: sessionId,
          cookie_expiration_date: new Date().getTime() + (1000 * 3600 * 4)
        }
        const {id_token, access_token, refresh_token, ...userData } = newUser;

        setUserCookie(cookieNames.idTokenLabel, cookifyString( id_token ));
        setUserCookie(cookieNames.accessTokenLabel, cookifyString( access_token ));
        setUserCookie(cookieNames.refreshTokenLabel, cookifyString( refresh_token ));
        setUserCookie(cookieNames.userData, cookifyObj( userData ));
        dispatch(trackingInit())
      } else {
        dispatch(trackEvent({ eventCategory: 'land', values: true, event: 'load_app' }))
      }
    }
  }, [user])

  useEffect(() => {
    if (sessionId) {
      dispatch(trackEvent({ eventCategory: 'session', values: true, event: 'login' }))
    }
  }, [sessionId])
  
  

  if (!user && isCookieValid) {
    return <LoadingScreen />
  }
  
  return (
    <StyledPrivateRoute expand className={"route"}>
      <Container className="body">
        <Outlet />
      </Container>
    </StyledPrivateRoute>
  )
}

export default PrivateRoute

